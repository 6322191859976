import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
// import { PrivateRoute } from 'Utils/protectedRoute'
import Loader from './BaseComponents/Loader'
const Reminder = React.lazy(() => import('./Containers/Reminder'))
const Success = React.lazy(() => import('./Containers/Success'))
const Error = React.lazy(() => import('./Containers/Error'))

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route>
              <Route path="/loading" element={<Loader />} />
              <Route path="/success" element={<Success />} />
              <Route path="/error" element={<Error />} />
              <Route path="/:id" element={<Reminder />} />
            </Route>
            <Route path="*" element={<Navigate to="/error" />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App
