import { createAsyncThunk } from "@reduxjs/toolkit"
import ReminderService from "./reminder.services"

export const checkPin = createAsyncThunk('reminder/checkpin', async (pin) => {
  try {
    const res = await ReminderService.verifyPin(pin)
    return res.data
  } catch (error) {
    console.log(error)
  }
})
export const submit = createAsyncThunk('reminder/submit', async (data) => {
  try {
    const res = await ReminderService.submit(data)
    return res.data
  } catch (error) {
    console.log(error)
  }
})

export const getIp = createAsyncThunk('reminder/getIp', async () => {
  try {
    const res = await ReminderService.getIp()
    return res.data
  } catch (error) {
    console.log(error)
  }
})
export const getFlag = createAsyncThunk('reminder/getFlag', async (data) => {
  try {
    const res = await ReminderService.getFlag(data)
    return res.data
  } catch (error) {
    console.log(error)
  }
})