import httpCommon from 'Redux/http/httpCommon'
import { Apis } from 'Redux/APIs'
import axios from 'axios'

class ReminderService {
  verifyPin(pin) {
    return httpCommon.get(`${Apis.Login_verifyPin}/${pin}`)
  }
  submit(data) {
    return httpCommon.post(`${Apis.Login_submit}`, data)
  }
  getIp() {
    return axios.get(`https://geolocation-db.com/json/`)
  }
  getFlag(data) {
    return httpCommon.get(`${Apis.Login_getFlag}/${data}`)
  }
}

export default new ReminderService()
