import { checkPin, getFlag, getIp, submit } from './reminder.actions'
import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  pinResponse: {},
  submitResponse: {},
  flagResponse: {},
  ipResponse: {},
}

const reminderSlice = createSlice({
  name: 'reminder',
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder
      .addCase(checkPin.fulfilled, (state, action) => {
        state.pinResponse = action.payload
      })
      .addCase(submit.fulfilled, (state, action) => {
        state.submitResponse = action.payload
      })
      .addCase(getIp.fulfilled, (state, action) => {
        state.ipResponse = action.payload
      })
      .addCase(getFlag.fulfilled, (state, action) => {
        state.flagResponse = action.payload
      })
  },
})

const { reducer } = reminderSlice
export default reducer
