import { configureStore } from '@reduxjs/toolkit'
import reminderReducer from './reminder/reminder.reducers'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  reminder: reminderReducer,
})

const store = configureStore({
  reducer: rootReducer,
})

export default store
